import React, { useState, useEffect } from "react";
import Axios from "axios";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import "jspdf-autotable";
// import html2pdf from "html2pdf.js";
// import { useRef } from "react";
import { API_BASE_URL } from "../Config/Config";


const ViewQuote = ({ QUOOBJ }) => {

    const [userDataSession, setUserDataSession] = useState([]);
    const [formattedDate, setFormattedDate] = useState("");
    const [loading, setLoading] = useState("");
    const [firstData, setFirstData] = useState(null);
    const [totalAmount, setTotalAmount] = useState("");
    const [taxAmount, setTaxAmount] = useState("");
    const [viewProducts, setViewProducts] = useState([]);

  // #region Session userdetails
  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    if (userDataString) {
      const userData = JSON.parse(userDataString);
      setUserDataSession(userData);
    } else {
      console.log("User data not found in sessionStorage");
    }
  }, []);

  useEffect(() => {
    if (QUOOBJ) {
      console.log(QUOOBJ, "QUOOBJQUOOBJ");
      if (QUOOBJ.QuoteDate) {
        const poDate = new Date(QUOOBJ.QuoteDate);
        const formattedPODate = poDate.toISOString().split("T")[0];
        setFormattedDate(formattedPODate);
      }
    }
  }, [QUOOBJ]);

  useEffect(() => {
    if (userDataSession && userDataSession.OrgId && QUOOBJ && QUOOBJ.QuotionId) {
      setLoading(true);
      const url = `${API_BASE_URL}Inventory/GetPOById?UserId=${userDataSession.Id}&PurchaseId=${QUOOBJ.QuotionId}`;
      Axios.get(url)
        .then((response) => {
          if (response.data.Status) {
            setViewProducts(response.data.ResultData);
            setFirstData(response.data.ResultData[0]);
            console.log(response.data.ResultData, "req details");
            setLoading(false);
          } else {
            console.error("Error fetching data:", response.data.Message);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [userDataSession, QUOOBJ]);

  useEffect(() => {
    let totalTaxAmt = 0;
    let totalAmount = 0;

    if (viewProducts && viewProducts.length > 0) {
      totalTaxAmt = viewProducts.reduce((acc, product) => {
        const taxRate = product.CGSTRate + product.SGSTRate;
        const taxAmount =
          (product.UnitPrice * product.Quantity * taxRate) / 100;
        return acc + (taxAmount || 0);
      }, 0);

      totalAmount = viewProducts.reduce((acc, product) => {
        const amount = product.UnitPrice * product.Quantity;
        return acc + (amount || 0);
      }, 0);
    }

    setTaxAmount(totalTaxAmt);
    setTotalAmount(totalAmount + totalTaxAmt - QUOOBJ?.Advance);
  }, [viewProducts]);

  //region pdf download
//   const printRef = useRef();
//   const [showPDF, setShowPDF] = useState(false);

//   const generatePDF = () => {
//     setShowPDF(true);

//     const element = printRef.current;

//     html2pdf()
//       .from(element)
//       .save("purchase_order.pdf")
//       .then(() => {
//         setShowPDF(false);
//       });
//   };

  return (
    <div>
    
      <div
        className="offcanvas offcanvas-end"
        tabIndex={-1}
        id="offcanvasRightQUOTEView"
        aria-labelledby="offcanvasRightLabel"
        style={{ minWidth: "55%", maxWidth: "55%" }}
      >
        <div className="offcanvas-header">
          <h4 id="offcanvasRightLabel" className="me-3">
            View Quotation ({QUOOBJ ? QUOOBJ.QuoteNo : ""})
          </h4>

          {/* {showPDF ? (
            <tr>
              <td colSpan="12" className="text-center">
                Downloading...
              </td>
            </tr>
          ) : (
            <ul className="table-top-head">
              <li onClick={generatePDF}>
                  <Link>
                    <i className="fa-solid fa-file-arrow-down fa-xl"></i>
                  </Link>
              </li>
            </ul>
          )} */}

          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <hr />
        <div className="offcanvas-body">
          <form>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-blocks">
                  <label>Date</label>
                  <div className="input-groupicon calender-input">
                    <input
                      type="date"
                      className="form-control"
                      value={formattedDate}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-blocks me-3">
                  <label className="form-label">Status</label>
                  <input
                    className="form-control"
                    type="text"
                    value={QUOOBJ ? QUOOBJ.QuotationStatus : ""}
                    readOnly
                  />
                </div>
              </div>
              <div className=" col-lg-3 col-md-3form-group">
                <label className="form-label">Total Amount</label>
                <input
                  type="float"
                  className="form-control"
                  value={QUOOBJ ? QUOOBJ.TotalAmount : ""}
                  readOnly
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-lg-12">
                <div className="modal-body-table">
                  <div className="table-responsive">
                    <table className="table  dataew">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Product</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Units</th>
                          <th>Tax</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan="12" className="text-center">
                              Loading...
                            </td>
                          </tr>
                        ) : viewProducts.length !== 0 ? (
                          viewProducts.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.ProductName}</td>
                              <td>{item.Quantity}</td>
                              <td>{item.UnitPrice}</td>
                              <td>{item.Units}</td>
                              <td>
                                {item.CGSTRate}, {item.SGSTRate}
                              </td>
                              <td>
                                {(item.Quantity || 0) * (item.UnitPrice || 0) +
                                  (((item.CGSTRate || 0) +
                                    (item.SGSTRate || 0)) /
                                    100) *
                                    (item.Quantity || 0) *
                                    (item.UnitPrice || 0)}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="8" className="text-center">
                              No Data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div className="justify-content-between d-flex mt-2">
                      <p className="badge badge-primary fs-6 col-4">
                        Total Advance:{" "}
                        <span className="fw-bold fs-5">
                          {Number(QUOOBJ?.Advance || 0).toFixed(2)}
                        </span>
                      </p>
                      <p className="badge badge-primary fs-6 col-3">
                        Total Tax:{" "}
                        <span className="fw-bold fs-5">
                          {Number(taxAmount || 0).toFixed(2)}
                        </span>
                      </p>
                      <p
                        style={{ height: "1.8rem" }}
                        className="badge badge-primary fs-6 col-4"
                      >
                        Total Amount:{" "}
                        <span className="fw-bold fs-5">
                          {Number(QUOOBJ?.TotalAmount - QUOOBJ?.Advance || 0).toFixed(2)}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="input-blocks">
                    <label>Shipment Details</label>
                    <textarea value={QUOOBJ?.Notes} readOnly></textarea>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* <div
        ref={printRef}
        className="container"
        style={{
          width: "800px",
          margin: "0 auto",
          display: showPDF ? "block" : "none",
        }}
      >
        <div
          className="center-image"
          style={{ textAlign: "center", margin: "10px" }}
        >
          <img
            src={`assets/img/${userDataSession.OrgId}.png`}
            alt="Purchase Order Image"
            width="190px"
            height="80px"
          />
        </div>
        <hr />

        <div
          className="d-flex"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            gap: "20px",
            paddingLeft: "15px",
          }}
        >
          <h2 style={{ textAlign: "left", color: "#FF9F43" }}>
            PURCHASE ORDER
          </h2>
          <div>
            <p style={{ margin: 0 }}>Date: {new Date().toLocaleDateString()}</p>
            <h2 style={{ textAlign: "left", color: "#FF9F43", margin: 0 }}>
              PO - {firstData?.AutoGenTransactionNo || ""}
            </h2>
          </div>
        </div>

        <div
          className="vendor-ship"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <div style={{ width: "48%" }}>
            <p
              className="ship"
              style={{
                backgroundColor: "#FF9F43",
                color: "white",
                padding: "10px",
                fontWeight: "bold",
              }}
            >
              <strong>SUPPLIER DETAILS</strong>
            </p>
            <p>Supplier Name: {firstData?.SupplierName || ""}</p>
            <p>PO Date: {formattedDate} </p>
            <p>Status: {QUOOBJ?.POStatus || ""}</p>
            <p>Discount: {firstData?.DiscountAmount || ""}</p>
          </div>
          <div style={{ width: "48%" }}>
            <p
              className="ship"
              style={{
                backgroundColor: "#FF9F43",
                color: "white",
                padding: "10px",
                fontWeight: "bold",
              }}
            >
              <strong>SHIP TO</strong>
            </p>
            <p>{QUOOBJ?.Notes || ""}</p>
          </div>
        </div>

        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginBottom: "15px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #000",
                  padding: "10px",
                  textAlign: "left",
                  backgroundColor: "#FF9F43",
                  color: "white",
                }}
              >
                #
              </th>
              <th
                style={{
                  border: "1px solid #000",
                  padding: "10px",
                  textAlign: "left",
                  backgroundColor: "#FF9F43",
                  color: "white",
                }}
              >
                PRODUCT
              </th>
              <th
                style={{
                  border: "1px solid #000",
                  padding: "10px",
                  textAlign: "left",
                  backgroundColor: "#FF9F43",
                  color: "white",
                }}
              >
                QUANTITY
              </th>
              <th
                style={{
                  border: "1px solid #000",
                  padding: "10px",
                  textAlign: "left",
                  backgroundColor: "#FF9F43",
                  color: "white",
                }}
              >
                PRICE
              </th>
              <th
                style={{
                  border: "1px solid #000",
                  padding: "10px",
                  textAlign: "left",
                  backgroundColor: "#FF9F43",
                  color: "white",
                }}
              >
                UNITS
              </th>
              <th
                style={{
                  border: "1px solid #000",
                  padding: "10px",
                  textAlign: "left",
                  backgroundColor: "#FF9F43",
                  color: "white",
                }}
              >
                TAX
              </th>
              <th
                style={{
                  border: "1px solid #000",
                  padding: "10px",
                  textAlign: "left",
                  backgroundColor: "#FF9F43",
                  color: "white",
                }}
              >
                AMOUNT
              </th>
            </tr>
          </thead>
          <tbody>
            {viewProducts.map((item, index) => (
              <tr key={item.index}>
                <td style={{ border: "0.5px solid #000", padding: "10px" }}>
                  {index + 1}
                </td>
                <td style={{ border: "0.5px solid #000", padding: "10px" }}>
                  {item.ProductName}
                </td>
                <td style={{ border: "0.5px solid #000", padding: "10px" }}>
                  {item.Quantity}
                </td>
                <td style={{ border: "0.5px solid #000", padding: "10px" }}>
                  {item.UnitPrice}
                </td>
                <td style={{ border: "1px solid #000", padding: "10px" }}>
                  {item.Units}
                </td>
                <td style={{ border: "1px solid #000", padding: "10px" }}>
                  {`${item.CGSTRate + item.SGSTRate}%`}
                </td>
                <td style={{ border: "1px solid #000", padding: "10px" }}>
                  {(
                    (item.Quantity || 0) * (item.UnitPrice || 0) +
                    (((item.CGSTRate || 0) + (item.SGSTRate || 0)) / 100) *
                      (item.Quantity || 0) *
                      (item.UnitPrice || 0)
                  ).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div
          className="d-flex row"
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginRight: "-15px",
            marginLeft: "-15px",
            paddingLeft: "15px",
          }}
        >
          <div
            className="comments col-6"
            style={{
              flex: "0 0 50%",
              maxWidth: "50%",
              backgroundColor: "#f0f0f0",
              padding: "10px",
            }}
          >
            <p>
              <strong>Comments or Special Instructions:</strong>
            </p>
            <p>Thank you for your business.</p>
          </div>
          <div
            className="totals col-4"
            style={{
              flex: "0 0 41.66%",
              maxWidth: "41.66%",
              borderStyle: "solid",
              borderWidth: "1px",
              padding: "10px",
            }}
          >
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ border: "none", textAlign: "left" }}>
                    Total Discount
                  </td>
                  <td style={{ border: "none", textAlign: "left" }}>
                    {Number(QUOOBJ?.DiscountAmount || 0).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "none", textAlign: "left" }}>
                    Total Tax
                  </td>
                  <td style={{ border: "none", textAlign: "left" }}>
                    {Number(taxAmount || 0).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Total Amount</strong>
                  </td>
                  <td
                    className="total"
                    style={{
                      border: "none",
                      padding: "10px",
                      textAlign: "left",
                      backgroundColor: "#FF9F43",
                      color: "white",
                    }}
                  >
                    {Number(totalAmount || 0).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          className="footer"
          style={{
            fontSize: "12px",
            textAlign: "center",
            color: "gray",
            marginTop: "20px",
          }}
        >
          <p>
            If you have any questions about this purchase order, please contact
          </p>
        </div>
      </div> */}
    </div>
  );
};

ViewQuote.propTypes = {
  QUOOBJ: PropTypes.object.isRequired,
};

export default ViewQuote;
