import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import IbizLogo from '../../Assests/Images/41115.png';
import SideImg from '../../Assests/Images/5.png';
import { API_BASE_URL } from '../Config/Config';


export default function SignIn() {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [otpError, setOtpError] = useState('');
    const [displayEmail, setDisplayEmail] = useState(true);
    const [displayOtp, setDisplayOtp] = useState(false);
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState(new Array(6).fill(''));
    const otpInputRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
   
    const navigate = useNavigate();

    const [userDataSession, setUserDataSession] = useState([]);

    const [countdown, setCountdown] = useState(null);
    const [canResend, setCanResend] = useState(false);
    const [otpCode, setOtpCode] = useState(otp); 

    useEffect(() => {
        const userDataString = sessionStorage.getItem("userData");
        if (userDataString) {
            const userData = JSON.parse(userDataString);
            
            setUserDataSession(userData);
            console.log(userData)
            const enteredOtp = otp.join('');
            const storedOtp = sessionStorage.getItem('otp');
             
            if (enteredOtp === storedOtp) {
                // navigate('/supplier-registration');
                navigate('/dashboard');
            } 
            
        } else {
            console.log("User data not found in sessionStorage");
        }
    }, [navigate,setUserDataSession]);

    useEffect(() => {
        setCountdown(30);
    }, [displayOtp]);

    useEffect(() => {
        const handleBackspace = (e) => {
            if (e.key === 'Backspace') {
                const index = otp.findIndex(digit => !digit);
                if (index > 0) {
                    otpInputRefs[index - 1].current.focus();
                }
            }
        };

        window.addEventListener('keydown', handleBackspace);

        return () => {
            window.removeEventListener('keydown', handleBackspace);
        };
    }, [otp, otpInputRefs]);

    const handleOtpChange = (index, value) => {
        if (/^[0-9]*$/.test(value) && value.length <= 1) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);


            if (value && index < otp.length - 1) {
                otpInputRefs[index + 1].current.focus();
            } else if (!value && index > 0) {
                otpInputRefs[index - 1].current.focus();
            }
        }
    };

    const maskEmail = (email) => {
        const [localPart, domain] = email.split("@");
        
        if (localPart.length > 5) {
          const visibleLocalPart = localPart.slice(0, 5);
          const maskedLocalPart = visibleLocalPart + "***";
          const domainName = domain.split(".")[0];
          const maskedDomain = domainName.slice(0, -8) + "***";
          const domainExtension = domain.split(".")[1];
          
          return `${maskedLocalPart}@${maskedDomain}.${domainExtension}`;
        } 
        
        return email;
    };

    const handleDisplayOtp = async (e) => {
        e.preventDefault();
        if (!email) {
            setErrorMessage('Please enter your email.');
            return;
        }
    
        setLoading(true);
        setErrorMessage('');
    
        try {
            const response = await fetch(`${API_BASE_URL}Inventory/GetSupplierByEmailId?EmailId=${email}`, {
                method: 'GET',
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                setErrorMessage(errorData.error || 'An error occurred. Please try again.');
                return;
            }
    
            const data = await response.json();
            console.log(data)
            if (data.error == 'No records found') {
                setErrorMessage('User not registered..');
                return;
            }
    
            if (data && data.ResultData.length > 0) {
                const otp = Math.floor(100000 + Math.random() * 900000).toString();

                sessionStorage.setItem("userData", JSON.stringify(data.ResultData[0]));
                const userDataSession = JSON.parse(sessionStorage.getItem("userData"));
                console.log("User Data from Session:", userDataSession); 

                const orgid = userDataSession?.OrgId;
                
                if (orgid) {
                    
                    const emailSent = await sendOtpToEmail(email, otp, orgid);    
                    
                        setDisplayEmail(false);
                        setDisplayOtp(true);
                        sessionStorage.setItem('otp', otp);
                   
                } 
            } else {
                setErrorMessage('No supplier found with the provided email.');
            }
        } catch (error) {
            console.error('Error fetching supplier data:', error);
            setErrorMessage('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    
    const sendOtpToEmail = async (email, otp, orgid) => {
        const emailData = {
            superid: orgid,
            toaddr: email,
            message: `
                        <div style="text-align: center; margin-bottom: 1rem;">
                            <img src="https://res.cloudinary.com/dbuewcovx/image/upload/v1731919498/rlqqk0a4j8u7cgkf4soj.png" 
                                alt="Ibiz Logo" width="140" height="70" style="display:block; margin: auto;" />
                        </div>
                        <br></br>
                        Dear Vendor,
                        <br></br>
                        To complete your sign-in to the Vendor Portal, please use the following 
                        <br></br>
                        OTP code: <strong>${otp}</strong>
                        <br><br>
                        This code is valid for a limited time. If you did not request this code, please ignore this message.
                        <br></br>
                        Thank you, <br></br>Powered by <a href="https://perennialcode.in/">PerennialCode</a> 
                    `,
            subject: "Vendor Portal OTP Verification Code"
        };

        console.log(emailData);
    
        try {
            const emailResponse = await fetch('https://notifyservisesrc.azurewebsites.net/notify/sendmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(emailData),
            });
    
            const responseData = await emailResponse.json();
    
            // Log the response data for debugging
            console.log('Email Service Response:', responseData);
    
            return responseData.success;
        } catch (error) {
            console.error('Error sending OTP:', error);
            return false;
        }
    };

    useEffect(() => {
        if (countdown > 0) {
          const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
          return () => clearTimeout(timer);
        } else {
          setCanResend(true);
        }
    }, [countdown]);
    
    //region Handle OTP resend
    const handleResendOtp = () => {
        const newOtp = Math.floor(100000 + Math.random() * 900000);
        setOtpCode(newOtp);
        sessionStorage.setItem('otp', newOtp);
    
        sendOtpToEmail(email, newOtp, userDataSession.OrgId);
        
        setCountdown(30);
        setCanResend(false);
    };
    
    const handleSubmitOtp = () => {

        const enteredOtp = otp.join('');
        const storedOtp = sessionStorage.getItem('otp');

        if (enteredOtp === storedOtp) {
            navigate('/dashboard');
        } else {
            setOtpError('Invalid OTP. Please try again.');
        }
    };


    return (
        <div className="main-wrapper d-flex">
            <div className="account-content flex-grow-1 d-flex col-md-6 col-lg-6 col-12">
                <div className="login-wrapper flex-grow-1 d-flex">
                    <div className="login-content flex-grow-1">
                        {displayEmail && (
                            <form onSubmit={handleDisplayOtp}>
                                <div className="login-userset">
                                    <div className="login-logo logo-normal">
                                        <img src={IbizLogo} alt="Ibizlogo" />
                                    </div>
                                    <div className="login-userheading">
                                        <h3 className='text-center'>Sign In</h3>
                                    {/*  <h4>Perennial POS application has features that help you build <br /> solid relationships with customers, track sales activities, <br />automate and keep sales processes streamlined.</h4> */} 
                                    </div>
                                    <div className="form-login mb-3">
                                        <label className="form-label">Email Id</label>
                                        <div className="form-addons position-relative">
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Enter email address"
                                                required
                                                autoComplete="off"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <i className="fa-regular fa-envelope position-absolute end-0 me-3" style={{ top: '50%', transform: 'translateY(-50%)' }}></i>
                                        </div>
                                    </div>
                                    <p className="text-danger fw-bold">{errorMessage}</p>
                                    <div className="form-login">
                                        <button
                                            type="submit"
                                            className="btn btn-login"

                                            disabled={loading}
                                        >
                                            {loading ? 'Signing in...' : 'Sign In'}
                                        </button>
                                    </div>
                                    <div className="signinform">
                                        <h4>
                                            New on our platform?
                                            <Link to="/signup" className="hover-a"> Create an account</Link>
                                        </h4>
                                    </div>
                                    <div className="form-sociallink">
                                        <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                                            <p>Copyright © 2024 <a href="http://www.perennialcode.com/" target="_blank" rel="noreferrer">Perennialcode.</a> All rights reserved</p>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                       {displayOtp && (
                            <div className="p-2 text-center">
                                <div className="login-logo logo-normal">
                                    <img src={IbizLogo} alt="Ibizlogo" />
                                </div>
                                <h4>Enter OTP</h4>
                                <div>
                                    <span>A code has been sent to <b className='text-success'>{maskEmail(email)}</b></span>
                                </div>

                                {/* Injecting styles to remove number input arrows */}
                                <style
                                    dangerouslySetInnerHTML={{
                                        __html: `
                                            input[type="number"] {
                                            -moz-appearance: textfield;
                                            appearance: textfield;
                                            }
                                            input[type="number"]::-webkit-outer-spin-button,
                                            input[type="number"]::-webkit-inner-spin-button {
                                            -webkit-appearance: none;
                                            margin: 0;
                                            }
                                        `,
                                    }}
                                />

                                <div id="otp" className="inputs d-flex flex-row justify-content-center mt-5">
                                    {otp.map((digit, index) => (
                                        <input
                                            key={index}
                                            className="m-2 text-center form-control shadow"
                                            type="number"
                                            maxLength="1"
                                            style={{ 
                                                width: '2.7rem',
                                                MozAppearance: 'textfield',
                                                appearance: 'textfield',
                                            }}
                                            value={digit}
                                            onChange={(e) => handleOtpChange(index, e.target.value)}
                                            ref={otpInputRefs[index]}
                                            required
                                        />
                                    ))}
                                </div>
                                {otpError && <p style={{ color: 'red' }}>{otpError}</p>}
                                <button type='button' className='btn btn-primary mt-3 text-capitalize' onClick={handleSubmitOtp}>Submit</button>
                                <div>
                                    {!canResend && <p>Please wait {countdown} seconds to resend OTP.</p>}
                                    {canResend && (
                                        <button
                                            type="button"
                                            className="btn btn-secondary mt-3"
                                            onClick={handleResendOtp}
                                        >
                                            Resend OTP
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    
                </div>
                
            </div>
            <div className="side-img-wrapper col-md-6 col-lg-6 col-12 d-none d-md-block d-lg-block">
                <img src={SideImg} alt="Side" className="side-img" />
            </div>
            
        </div>
    );
}
