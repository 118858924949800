import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import IbizLogo from '../../Assests/Images/iBizAccounts.png';
import SideImg from '../../Assests/Images/5.png';
import Swal from 'sweetalert2';
import Axios from 'axios';
import { API_BASE_URL, HARD_CODED_ORGID } from '../Config/Config';


export default function SignUp() {

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [otpError, setOtpError] = useState('');
    const [displayEmail, setDisplayEmail] = useState(true);
    const [displayOtp, setDisplayOtp] = useState(false);
    const otpInputRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
    const [email, setEmail] = useState('');
    const [userDataSession, setUserDataSession] = useState([]);
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(null);
    const [canResend, setCanResend] = useState(false);

    useEffect(() => {
        const userDataString = sessionStorage.getItem("userData");
        if (userDataString) {
          const userData = JSON.parse(userDataString);
          setUserDataSession(userData);
          console.log(userData)
        //   navigate('/supplier-registration');
        const enteredOtp = otp.join('');
        const storedOtp = sessionStorage.getItem('otp');

        if (enteredOtp === storedOtp) {
            navigate('/supplier-registration');
            console.log(userDataSession, 'userDataSession userDataSession');
        }
        } else {
          console.log("User data not found in sessionStorage");
        }
    }, [navigate]);

    const [addFormData, setAddFormData] = useState({
        orgid: HARD_CODED_ORGID,
        userid: 0,
        Supplier: {
            id:0,
            SupplierName: "",
            EmailId: "",
            PhoneNo: "",
            IsVendor: 1,
            IsActive: 1,
            CreatedBy: 0
        },
        bank: []
    });

    const handleAddInputChange = (e) => {
        const { name, value } = e.target;
        const [objectName, key] = name.split(".");

        if (objectName && key) {
            setAddFormData((prevState) => ({
                ...prevState,
                [objectName]: {
                    ...prevState[objectName],
                    [key]: value,
                },
            }));
        } else {
            setAddFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const generateOtp = () => {
        return Math.floor(100000 + Math.random() * 900000).toString(); 
    };

    const handleAddSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        try {
            const emailId = addFormData.Supplier.EmailId;
    
            const getResponse = await Axios.get(`${API_BASE_URL}Inventory/GetSupplierByEmailId?EmailId=${emailId}`);
            console.log(getResponse.data, "Fetched supplier data getting email id...");
    
            if (getResponse.data.Status) {
                setLoading(false);
                console.log(getResponse.data, "Fetched supplier data getting if...");
                setErrorMessage('Email already exists.');
                return;
            } else {
                setLoading(true);
    
                const genOTP = generateOtp();
                sessionStorage.setItem('otp', genOTP);
    
                const emailSentStatus = await sendOtpToEmail(addFormData.Supplier.EmailId, genOTP, HARD_CODED_ORGID);
    
                if (emailSentStatus === true) {
                    setDisplayOtp(true);
                    setDisplayEmail(false);
                    setLoading(false);
                } else {
                    console.error("Failed to send OTP");
                    setLoading(false);
                }
                
                setLoading(false);
                // const supplierData = getResponse.data.ResultData[0];
                // sessionStorage.setItem('userData', JSON.stringify(supplierData));
            }
        } catch (getError) {
            console.error("Error fetching supplier data:", getError);
            const getErrorMessage = getError.response?.data?.error || "Failed to fetch supplier data";
            setErrorMessage(getErrorMessage);
            Swal.fire({
                title: "Error",
                text: getErrorMessage,
                icon: "error",
            });
            setLoading(false);
        }
    };    
    
    const handleOtpChange = (index, value) => {
        if (/^[0-9]*$/.test(value) && value.length <= 1) { 
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            setOtpError('');

            if (value && index < otp.length - 1) {
                otpInputRefs[index + 1].current.focus();
            } else if (!value && index > 0) {
                otpInputRefs[index - 1].current.focus(); 
            }
        }
    };

    const sendOtpToEmail = async (email, otp, orgid) => {
        setOtpError('');
        // const emailData = {
        //     superid: orgid,
        //     toaddr: email,
        //     message: `Your OTP is: ${otp}`,
        //     subject: "Your OTP Code"
        // };

        const emailData = {
            // <img src="https://res.cloudinary.com/dbuewcovx/image/upload/v1729597190/WhatsApp_Image_2024-10-22_at_17.09.11_kcwwza.jpg" 
            // alt="Ibiz Logo" width="140" height="70" style="display:block; margin: auto;" />
            superid: orgid,
            toaddr: email,
            message: `
                        <div style="text-align: center;">
                            <img src="https://res.cloudinary.com/dbuewcovx/image/upload/v1731919498/rlqqk0a4j8u7cgkf4soj.png" 
                            alt="Ibiz Logo" width="140" height="70" style="display:block; margin: auto;" />

                        </div>
                        Dear ${addFormData.Supplier.SupplierName},
                        Welcome to the Vendor Portal! To complete your registration, please use the following OTP code:
                        <br></br>
                        Dear Vendor,
                        <br></br>
                        To complete your sign-in to the Vendor Portal, please use the following 
                        <br></br>
                        OTP code: <strong>${otp}</strong>
                        <br><br>
                        This code is valid for a limited time. If you did not request this code, please ignore this message.
                        <br></br>
                        Thank you, <br></br>Powered by <a href="https://perennialcode.in/">PerennialCode</a> 
                    `,
            subject: "Vendor Portal Registration OTP"
        };
        

        console.log(emailData, 'emailData sednign to api otp');

        try {
            const emailResponse = await fetch('https://notifyservisesrc.azurewebsites.net/notify/sendmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(emailData),
            });

            const responseData = await emailResponse.json();

            console.log('Email Service Response:', responseData);

            return responseData.status; 
        } catch (error) {
            console.error('Error sending OTP:', error);
            return false; 
        }
    };

    const handleSubmitOtp = () => {
        const enteredOtp = otp.join('');
        const storedOtp = sessionStorage.getItem('otp');

        if (enteredOtp === storedOtp) {
            setLoading(true);
    
            const formattedData = {
                orgid: HARD_CODED_ORGID,
                userid: addFormData.userid,
                Supplier: {
                    ...addFormData.Supplier,
                },
                bank: addFormData.bank,
            };
    
            console.log(formattedData, "sending data to add suppplier API.");

            Axios.post(`${API_BASE_URL}Inventory/InsertSuppliers`, formattedData)
            .then((response) => {
                console.log(response.data, "Full response data");
    
                if (response.status === 200) {
                    if (response.data.error) {
                        if (response.data.error.includes("Email already exists")) {
                            setErrorMessage(response.data.error);
                            setLoading(false);
                            Swal.fire({
                                title: "Error",
                                text: response.data.error,
                                icon: "error",
                            });
                            return;
                        } else {
                            setErrorMessage(response.data.error);
                            setLoading(false);
                            Swal.fire({
                                title: "Error",
                                text: response.data.error,
                                icon: "error",
                            });
                            return;
                        }
                    }
    
                    const emailId = addFormData.Supplier.EmailId;
                    Axios.get(`${API_BASE_URL}Inventory/GetSupplierByEmailId?EmailId=${emailId}`)
                        .then((getResponse) => {
                            console.log(getResponse.data, "Fetched supplier data");
                            
                            const supplierData = getResponse.data.ResultData[0];
                            sessionStorage.setItem('userData', JSON.stringify(supplierData));
                            navigate('/supplier-registration');
                        })
                        .catch((getError) => {
                            console.error("Error fetching supplier data:", getError);
                            const getErrorMessage = getError.response?.data?.error || "Failed to fetch supplier data";
                            setErrorMessage(getErrorMessage);
                            Swal.fire({
                                title: "Error",
                                text: getErrorMessage,
                                icon: "error",
                            });
                        });
                } else {
                    Swal.fire({
                        title: "Error",
                        text: "Failed to register supplier.",
                        icon: "error",
                    });
                }
            })
            .catch((error) => {
                console.error("Error adding supplier:", error);
    
                const errorMessage = error.response?.data?.error || "An error occurred";
                setErrorMessage(errorMessage);
    
                Swal.fire({
                    title: "Error",
                    text: errorMessage,
                    icon: "error",
                });
    
                sessionStorage.setItem('errorMessage', errorMessage);
            })
            .finally(() => {
                setLoading(false);
            });
            
        } else {
            setOtpError('Invalid OTP. Please try again.');
        }
    };

    const maskEmail = (email) => {
        const [localPart, domain] = email.split("@");
        
        if (localPart.length > 5) {
          const visibleLocalPart = localPart.slice(0, 5);
          const maskedLocalPart = visibleLocalPart + "***";
          const domainName = domain.split(".")[0];
          const maskedDomain = domainName.slice(0, -8) + "***";
          const domainExtension = domain.split(".")[1];
          
          return `${maskedLocalPart}@${maskedDomain}.${domainExtension}`;
        } 
        
        return email;
    };

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        } else {
            setCanResend(true);
        }
    }, [countdown]);
    
    //region Handle OTP resend
    const handleResendOtp = () => {
        setOtpError('');
        const newOtp = Math.floor(100000 + Math.random() * 900000);
        sessionStorage.setItem('otp', newOtp);
    
        sendOtpToEmail(addFormData.Supplier.EmailId, newOtp, HARD_CODED_ORGID);
        
        setCountdown(30);
        setCanResend(false);
    };

       return (
            <div className="main-wrapper d-flex">
                <div className="account-content flex-grow-1 d-flex col-md-6 col-lg-6 col-12">
                    <div className="login-wrapper flex-grow-1 d-flex">
                        <div className="login-content flex-grow-1">
                            {displayEmail && (
                                <form onSubmit={handleAddSubmit}>
                                    <div className="login-userset">
                                        <div className="login-logo logo-normal">
                                            <img src={IbizLogo} alt="Ibizlogo" />
                                        </div>
                                        <div className="login-userheading">
                                            <h3>Sign Up</h3>
                                            <h4>Perennial POS application has features that help you build <br /> solid relationships with customers, track sales activities, <br />automate and keep sales processes streamlined.</h4>
                                        </div>
    
                                        <div className="form-login mb-3">
                                            <label className="form-label">Name</label>
                                            <div className="form-addons position-relative">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="Supplier.SupplierName"
                                                    value={addFormData.Supplier.SupplierName}
                                                    onChange={handleAddInputChange}
                                                    placeholder="Enter supplier name"
                                                    required
                                                />
                                            </div>
                                        </div>
    
                                        <div className="form-login mb-3">
                                            <label className="form-label">Email Id</label>
                                            <div className="form-addons position-relative">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    name="Supplier.EmailId"
                                                    value={addFormData.Supplier.EmailId}
                                                    onChange={handleAddInputChange}
                                                    placeholder="Enter email"
                                                    required
                                                />
                                                <i className="fa-regular fa-envelope position-absolute end-0 me-3" style={{ top: '50%', transform: 'translateY(-50%)' }}></i>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div className="form-login mb-3 w-100">
                                                <label className="form-label">Mobile No</label>
                                                <div className="form-addons position-relative">
                                                    <input
                                                        type="tel"
                                                        className="form-control"
                                                        name="Supplier.PhoneNo"
                                                        value={addFormData.Supplier.PhoneNo}
                                                        onChange={handleAddInputChange}
                                                        placeholder="Enter phone number"
                                                        maxLength={10}
                                                        minLength={10}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <p className="mb-3 text-danger">{errorMessage}</p>
                                        <div className="form-login mb-3">
                                            <button className="btn btn-primary w-100" type="submit">
                                                {loading ? "Signing Up..." : "Sign Up"}
                                            </button>
                                        </div>
                                        <p className="mb-0 text-center">
                                            Already have an account? <Link to="/">Login Here</Link>
                                        </p>
                                    </div>
                                </form>
                            )}
        
                            {displayOtp && (
                                <div className="p-2 text-center">
                                    <div className="login-logo logo-normal">
                                        <img src={IbizLogo} alt="Ibizlogo" />
                                    </div>
                                    <h4>Enter OTP</h4>
                                    <div>
                                        <span>A code has been sent to <b className='text-success'>{maskEmail(addFormData.Supplier.EmailId)}</b></span>
                                    </div>
                                    <div id="otp" className="inputs d-flex flex-row justify-content-center mt-5">
                                        {otp.map((digit, index) => (
                                            <input
                                                key={index}
                                                className="m-2 text-center form-control shadow"
                                                type="text"
                                                maxLength="1"
                                                style={{ width: '2.7rem' }}
                                                value={digit}
                                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                                ref={otpInputRefs[index]}
                                                required
                                            />
                                        ))}
                                    </div>
                                    {otpError && <p style={{ color: 'red' }}>{otpError}</p>}
                                    <button type='button' className='btn btn-primary mt-3 text-capitalize' onClick={handleSubmitOtp}>{loading ? 'Submitting...' : 'Submit'}</button>
                                    <div>
                                        {!canResend && <p>Please wait {countdown} seconds to resend OTP.</p>}
                                        {canResend && (
                                            <button
                                                type="button"
                                                className="btn btn-secondary mt-3"
                                                onClick={handleResendOtp}
                                            >
                                                Resend OTP
                                            </button>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="login-image col-md-6 d-none d-lg-block">
                        <img src={SideImg} alt="SideImage" />
                    </div>
                </div>
            </div>
        );
        
    
}
