
const API_BASE_URL = 'https://ibizapi.ibizaccounts.in/';

// const API_BASE_URL = 'https://qaibizapi.ibizaccounts.in/';

// const API_BASE_URL = 'http://192.168.1.9:3007/';

// const API_BASE_URL = 'http://localhost:3009/';

const HARD_CODED_ORGID = 41115;

export { API_BASE_URL, HARD_CODED_ORGID };